import React from 'react'
import { Layout, SEO } from '../components'
import { useSiteMetadata } from '../hooks/useSiteMetadata'
import styled from 'styled-components'

const Contact = () => {

  const { description, email } = useSiteMetadata()

  return (
    <Layout>
      <SEO title="Contact" />
      <Wrapper>
        <article>
          <h3>문의</h3>
          <div className="contact-info">{description}</div>
          <address>{email}</address>
        </article>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.section`
  height: 90vh;
  width: 92vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 2rem;
  align-items: top;
  margin-top: 8rem;
  text-align: justify;
  h3 {
    margin-bottom: 2rem;
  }

  .contact-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    .form-row {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
    }
  }
`

export default Contact

/*
<article>
  <form className="form contact-form">
    <div className="form-row">
      <label htmlFor="name">이름</label>
      <input type="text" name="name" id="name" />
    </div>
    <div className="form-row">
      <label htmlFor="email">이메일</label>
      <input type="text" name="email" id="email" />
    </div>
    <div className="form-row">
      <label htmlFor="message">메시지</label>
      <textarea name="message" id="message"></textarea>
    </div>
    <button type="submit" className="btn block">
      보내기
    </button>
  </form>
</article>
*/
